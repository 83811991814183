import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import General from './General';
import Overrides from './Overrides';
import { Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import { DATA_SOURCES } from 'constants/data-sources';
import { CONFIG as tableConfig } from '../panels/table';
import { allPanelConfigs } from '../panels';

const Header = ({ children }) => (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    fontWeight={600}
    color="text.secondary"
  >
    {children}
  </Typography>
);

const panels = allPanelConfigs.map((panel) => ({
  ...panel,
  label: panel.name,
  value: panel.id,
}));

const allTabs = [
  { id: 'general', name: 'General', Component: General },
  { id: 'overrides', name: 'Overrides', Component: Overrides },
];

const PanelSettings = ({ values, setFieldValue, frames, resetResult }) => {
  const [tab, setTab] = useState(allTabs[0].id);

  const onTabChange = (e, val) => {
    setTab(val);
  };

  const onPanelChange = (val) => {
    const key = 'node_configs.0';

    const defaultConfig = panels.find((panel) => panel.value === val).default;
    const { dataset: prevDataset, query_type: prevQueryType } = panels.find(
      (panel) => panel.value === values.panelType,
    ).default;

    const shouldReset =
      defaultConfig.dataset !== prevDataset ||
      defaultConfig.query_type !== prevQueryType;

    if (shouldReset) {
      setFieldValue(key, {
        ...values.node_configs[0],
        datasourceId: DATA_SOURCES.KLOUDMATE,
        filters: [],
        groupBy: [],
        columns: [],
        orderBy: [],
        ...defaultConfig,
      });
      resetResult();
    } else {
      setFieldValue(
        'node_configs',
        values.node_configs.map((nc) => ({
          ...nc,
          responseType: defaultConfig.responseType,
        })),
      );
    }

    setTab(allTabs[0].id);
  };

  const { settings } = panels.find((p) => p.id === values.panelType);
  const tabs = allTabs.filter((tab) => !!settings[tab.id]);

  return (
    <Stack spacing={2} padding={1} component={Paper}>
      <Header>Settings</Header>

      <Stack spacing={1}>
        <Field
          fullWidth
          transformValue
          margin="none"
          name="panelType"
          label="Panel Type"
          component={Select}
          onChange={onPanelChange}
          options={panels.filter((type) => {
            if (type.value !== tableConfig.id) {
              return true;
            }
            const hasCWNodes = (values.node_configs || []).some(
              (nc) =>
                nc.type === 'query' &&
                nc.datasourceId !== DATA_SOURCES.KLOUDMATE,
            );
            return !hasCWNodes;
          })}
        />
      </Stack>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
          <TabList onChange={onTabChange}>
            {tabs.map(({ name, id }, idx) => (
              <Tab key={id} value={id} label={name} />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ Component, id }) => (
          <TabPanel key={id} value={id} sx={{ px: 0, pt: 0.5 }}>
            <Component
              setFieldValue={setFieldValue}
              values={values}
              frames={frames}
              items={settings[id]}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Stack>
  );
};

export default PanelSettings;
