import { useMemo, useState } from 'react';
import DataTable from 'components/DataTable';
import { getLabelsString } from 'modules/alarms/components/ExpressionResult';
import orderBy from 'lodash/orderBy';
import { format, formatResultToString } from '../utils';
import isNil from 'lodash/isNil';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import { CONFIG as columnConfig } from '../../PanelSettings/TablePanelColumns';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { DATA_SET } from 'constants/data-sources';
import { defaultAggregate } from 'modules/alarms/components/kloudmate/Aggregation';

export const CONFIG = {
  id: 'table',
  settings: {
    general: [panelConfig, columnConfig, timeConfig],
    overrides: null,
  },
  name: 'Table',
  description: 'Table',
  multiNodes: true,
  default: {
    query_type: QUERY_TYPE.AGG,
    dataset: DATA_SET.METRICS,
    aggregation: defaultAggregate,
    limit: null,
    responseType: 'number',
  },
  showTableView: true,
  configurableSource: true,
};

export const onChangeUnitTable = ({
  nodeId,
  format,
  values,
  setFieldValue,
}) => {
  (values.table?.columns || []).forEach((col, idx) => {
    const id = col.key.split(':')[0];
    if (id === nodeId) setFieldValue(`table.columns.${idx}.format`, format);
  });
};

const TablePanel = ({
  data,
  config,
  height,
  runQuery,
  rowId,
  multiNodes = CONFIG.multiNodes,
}) => {
  const [sort, setSort] = useState();
  const columnConfigs = config.table?.columns || [];

  const { columns, rows, total, page } = useMemo(() => {
    const rows = {};
    const columns = {};
    let total = null;
    let page = 1;

    let limit;
    if (!multiNodes) {
      if (!data?.length || !data[0].rows.length) {
        return { columns: [], rows: [] };
      }

      const firstRow = data[0].rows[0];
      Object.keys(firstRow).forEach((column) => {
        if (column === rowId || typeof firstRow[column] === 'object') {
          return;
        }
        columns[column] = {
          text: column,
          id: column,
          dataField: column,
        };
      });

      data[0].rows.forEach((row) => {
        rows[row[rowId]] = row;
      });

      total = data[0].total;
      page = data[0].page;
    } else {
      const isNumber = (data || []).every((row) => row.type === 'number');
      if (!data || !isNumber) {
        return { columns: [], rows: [] };
      }

      const node =
        config.node_configs.find((nc) => (nc.orderBy || []).length > 0) || {};

      const nodeId = node.nodeId;
      limit = node.limit;

      orderBy(data, (o) => o.nodeId !== nodeId).forEach((row) => {
        const { labels, name, nodeId, values } = row;

        if (isNil(values[0])) {
          return;
        }

        const unit = (config.node_configs || []).find(
          (nc) => nc.nodeId === nodeId,
        )?.unit;

        const valColumn = nodeId === name ? name : `${nodeId}:${name}`;
        columns[valColumn] = {
          text: valColumn,
          dataField: valColumn,
          sortable: true,
          isValueCol: true,
          unit,
        };

        const rowId = getLabelsString(labels);
        rows[rowId] = {
          ...rows[rowId],
          id: rowId,
          ...labels,
          [valColumn]: values[0],
        };

        Object.keys(labels).forEach((label) => {
          columns[label] = {
            text: label,
            dataField: label,
            sortable: true,
          };
        });
      });
    }

    const modifiedCols = Object.values(columns).map((column) => {
      const colConfig = columnConfigs.find((col) => col.key === column.text);

      const formatting = colConfig?.format;
      return {
        ...column,
        text: colConfig?.rename || column.text,
        formatter: (val) =>
          formatting
            ? formatResultToString(format({ ...formatting, value: val }))
            : val,
      };
    });

    return {
      rows: Object.values(rows).slice(0, limit || undefined),
      columns: orderBy(modifiedCols, 'isValueCol', 'desc'), //place value columns to the right
      total,
      page,
    };
  }, [data, columnConfigs]);

  const orderedRows = useMemo(() => {
    if (!sort) {
      return rows;
    }
    return orderBy(rows, sort.sortField, sort.sortOrder);
  }, [rows, sort]);

  const onTableChange = async (type, props) => {
    if (type === 'sort') {
      setSort(props);
    }
    if (type === 'pagination') {
      const panelConfig = {
        ...config,
        node_configs: [
          {
            ...config.node_configs[0],
            page: props.page,
          },
        ],
      };
      await runQuery(panelConfig);
    }
  };

  // useMemo for smoother drag and drop
  const table = useMemo(
    () => (
      <DataTable
        columns={columns}
        data={orderedRows}
        onTableChange={onTableChange}
        tableContainerStyle={{ height: height }}
        stickyHeader
        pagination={
          !multiNodes && {
            limit: config.node_configs[0].limit,
            total,
            page,
            rowsPerPageOptions: [config.node_configs[0].limit],
          }
        }
        paginationProps={{
          size: 'small',
        }}
      />
    ),
    [height],
  );

  return table;
};

export default TablePanel;
