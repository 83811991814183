import { Field } from 'formik';
import TextField from 'components/Form/Formik/FormikInput';
import Select from 'components/Form/Formik/FormikReactSelect';
import Stack from '@mui/material/Stack';

const FORMAT_OPTIONS = {
  NUMBER: 'number',
  BYTES: 'bytes',
  PERCENTAGE: 'percentage',
  DUR_MS: 'duration',
  DUR_NS: 'duration_nano',
  UNFORMATTED: 'unformatted',
};

const Y_AXIS_FORMATS = [
  { label: 'Number', value: FORMAT_OPTIONS.NUMBER },
  { label: 'Bytes', value: FORMAT_OPTIONS.BYTES },
  { label: 'Percentage', value: FORMAT_OPTIONS.PERCENTAGE },
  { label: 'Duration (Milliseconds)', value: FORMAT_OPTIONS.DUR_MS },
  { label: 'Duration (Nanoseconds)', value: FORMAT_OPTIONS.DUR_NS },
  { label: 'Unformatted', value: FORMAT_OPTIONS.UNFORMATTED },
];

export const getUnitFormatting = (_unit) => {
  const { DUR_MS, DUR_NS, NUMBER, BYTES, UNFORMATTED } = FORMAT_OPTIONS;
  if (!_unit) {
    return { format: UNFORMATTED, decimals: 3 };
  }

  let format, suffix;
  const [unit = '', per] = _unit.split('/');
  suffix = per;
  switch (unit.trim().toLowerCase()) {
    case 'ns':
    case 'nanoseconds':
    case 'nanosecond': {
      format = { format: DUR_NS };
      break;
    }
    case 'ms':
    case 'milliseconds':
    case 'millisecond': {
      format = { format: DUR_MS };
      break;
    }
    case 's':
    case 'secs':
    case 'seconds':
    case 'second': {
      format = { format: DUR_MS, multiplyBy: 0.001 };
      break;
    }
    case 'us':
    case 'µs':
    case 'microseconds':
    case 'microsecond': {
      format = { format: DUR_MS, multiplyBy: 1000 };
      break;
    }
    case 'b':
    case 'by':
    case 'bytes':
    case 'byte': {
      format = { format: BYTES };
      break;
    }
    case 'mb':
    case 'MiBy': {
      format = { format: BYTES, multiplyBy: 1000000 };
      break;
    }
    case 'kb':
    case 'KBy':
    case 'KiBy': {
      format = { format: BYTES, multiplyBy: 1000 };
      break;
    }
    case 'gb':
    case 'GiBy': {
      format = { format: BYTES, multiplyBy: 1000000000 };
      break;
    }
    case '1':
    case 'one': {
      format = { format: NUMBER };
      break;
    }
    default: {
      format = { format: NUMBER };
      suffix = _unit;
      break;
    }
  }

  return { ...format, suffix, decimals: 3 };
};

const Formatter = ({ name }) => {
  return (
    <Stack spacing={1}>
      <Field
        fullWidth
        transformValue
        margin="none"
        name={`${name}.format`}
        label="Format"
        size="small"
        component={Select}
        options={Y_AXIS_FORMATS}
      />
      <Stack direction="row" spacing={1} alignItems={'center'}>
        <Field
          fullWidth
          type="number"
          margin="none"
          size="small"
          name={`${name}.decimals`}
          label="Max. number of decimals"
          component={TextField}
        />
        <Field
          fullWidth
          type="number"
          margin="none"
          size="small"
          name={`${name}.multiplyBy`}
          label="Multiply by number"
          component={TextField}
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems={'center'}>
        <Field
          fullWidth
          margin="none"
          name={`${name}.prefix`}
          label="Add a prefix"
          size="small"
          component={TextField}
        />
        <Field
          fullWidth
          margin="none"
          name={`${name}.suffix`}
          label="Add a suffix"
          size="small"
          component={TextField}
        />
      </Stack>
    </Stack>
  );
};

export const CONFIG = {
  id: 'formatter',
  name: 'Formatter',
  description: 'Format Values',
  Component: Formatter,
};

export default Formatter;
