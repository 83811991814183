import isNil from 'lodash/isNil';
import numeral from 'numeral';
import prettyMs from 'pretty-ms';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const trimTrailingZeroesAfterDecimal = (value) => {
  return value
    .toString()
    .replace(/(?<=\d+\.\d*)(0+)(?!\.)(?!\d)/g, '')
    .replace(/(?<=\d+)(\.)(?=$|\D)/g, '');
};

export const getValue = (v, defaultValue) => {
  return isNil(v) || v === '' ? defaultValue : v;
};

export const formatResultToString = (result) => {
  return `${result.prefix}${result.value}${result.suffix}`;
};

export const format = ({
  value,
  format,
  multiplyBy: _multiplyBy,
  decimals: _decimals,
  prefix: _prefix,
  suffix: _suffix,
}) => {
  const multiplyBy = getValue(_multiplyBy, 1);
  const dec = getValue(_decimals, 3);
  let decimals = dec;
  if (dec > 10) {
    decimals = 10;
  }
  if (dec < 0) {
    decimals = 0;
  }
  const prefix = getValue(_prefix, '');
  const suffix = getValue(_suffix, '');

  const formatDecimals = Array(decimals).fill(0).join('');
  let formatString = decimals === 0 ? '0' : `0[.]${formatDecimals}`;
  switch (format) {
    case 'bytes': {
      formatString += 'b';
      break;
    }
    case 'percentage': {
      formatString += '%';
      break;
    }
    case 'duration': {
      const val = value * multiplyBy;
      if (!isFinite(val) || val === null) return '-';
      return {
        value: trimTrailingZeroesAfterDecimal(
          prettyMs(val, {
            millisecondsDecimalDigits: decimals,
            secondsDecimalDigits: decimals,
          }),
        ),
        prefix,
        suffix,
      };
    }
    case 'duration_nano': {
      const val = value * multiplyBy;
      if (!isFinite(val) || val === null) return '-';
      return {
        value: trimTrailingZeroesAfterDecimal(
          prettyMs(val / 1000000, {
            millisecondsDecimalDigits: decimals,
            secondsDecimalDigits: decimals,
          }),
        ),
        prefix,
        suffix,
      };
    }
    case 'number': {
      formatString += 'a';
      break;
    }
    case 'unformatted':
    default:
      break;
  }

  const formattedValue = trimTrailingZeroesAfterDecimal(
    numeral(value * multiplyBy).format(formatString),
  );

  return {
    value: formattedValue,
    suffix,
    prefix,
  };
};

export const noDataMessage = (
  <Grid
    container
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h5" color="text.secondary">
      No Data
    </Typography>
  </Grid>
);
