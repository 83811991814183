import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dropdown from 'components/Form/Dropdown';
import { Operators } from 'constants/aggregations';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from 'components/IconButton';
import KeyField from './KeysField';
import ValueField from './ValuesField';
import { DATA_SET } from 'constants/data-sources';

const defaultFilters = [
  {
    field: '',
    operator: '=',
    value: undefined,
    type: '',
  },
];

const Filters = ({
  name,
  queryOptions,
  onChange,
  from,
  to,
  variableOptions,
  showHint,
  resolvedVariablesValues,
}) => {
  const operators =
    queryOptions.dataset === DATA_SET.ALARMS ? [Operators[0]] : Operators; // for alarms list panel, we allow one filter - "serviceName". serviceName might be available in  alarm_States.labels. It is not possible to apply other operators besides "=" because of the way it is stored (jsonb array of arrays)

  return (
    <Box mt={2}>
      <Stack mb={1} spacing={1} direction="row" alignItems="center">
        <Typography variant="body2">Add filters</Typography>
        <IconButton
          icon={<AddCircleIcon />}
          size="small"
          onClick={() =>
            onChange(`${name}.filters`, [
              ...queryOptions.filters,
              defaultFilters[0],
            ])
          }
        />
      </Stack>
      {!queryOptions.filters?.length && showHint && (
        <Typography variant="body2">
          Apply filters to narrow down your dataset. For example, you can filter
          metrics by a specific service or host.
        </Typography>
      )}
      <Stack spacing={1}>
        {(queryOptions.filters || []).map((filter, index) => {
          const operator = Operators.find((i) => i.value === filter.operator);

          return (
            !filter.hide && (
              <Box key={index}>
                <Grid container spacing={1}>
                  <Grid item md={4} lg={3}>
                    <KeyField
                      freeSolo
                      dataset={queryOptions.dataset}
                      name={`${name}.filters.${index}`}
                      onChange={onChange}
                      value={queryOptions.filters[index]}
                      from={from}
                      to={to}
                      variableOptions={variableOptions}
                      resolvedVariablesValues={resolvedVariablesValues}
                      filters={queryOptions.filters}
                    />
                  </Grid>
                  <Grid item>
                    <Dropdown
                      transformValue
                      size="small"
                      placeholder="Operator"
                      margin="none"
                      options={operators}
                      value={queryOptions.filters[index].operator}
                      onChange={(val) => {
                        onChange(`${name}.filters.${index}.operator`, val);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} lg={3}>
                    {operator?.requiresValue !== false && (
                      <ValueField
                        dataset={queryOptions.dataset}
                        variableOptions={variableOptions}
                        resolvedVariablesValues={resolvedVariablesValues}
                        name={`${name}.filters.${index}`}
                        onChange={onChange}
                        value={queryOptions.filters[index]}
                        field={queryOptions.filters[index].field}
                        type={queryOptions.filters[index].type}
                        from={from}
                        to={to}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="error"
                      size="small"
                      icon={<RemoveCircleIcon />}
                      onClick={() => {
                        onChange(
                          `${name}.filters`,
                          queryOptions.filters.filter((f, i) => i !== index),
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )
          );
        })}
      </Stack>
    </Box>
  );
};

export default Filters;
