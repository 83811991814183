import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

export const ListTitle = ({ children }) => (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    variant="subtitle1"
    color="text.secondary"
  >
    {children}
  </Typography>
);

const PanelSettings = ({ setFieldValue, values, items }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState([(items[1] || items[0]).id]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? expanded.concat([panel])
        : expanded.filter((item) => item !== panel),
    );
  };

  return (
    <Box>
      {items.map(({ id, Component, props, name }) => {
        return (
          <Accordion
            id={id}
            key={id}
            expanded={expanded.includes(id)}
            disableGutters
            onChange={handleChange(id)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <ListTitle>{name}</ListTitle>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: theme.palette.background.light }}
            >
              <Component
                {...props}
                setFieldValue={setFieldValue}
                values={values}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default PanelSettings;
