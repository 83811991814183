import { DATA_SET } from 'constants/data-sources';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as tableColConfig } from '../../PanelSettings/TablePanelColumns';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import { QUERY_TYPE } from 'modules/alarms/utils';
import TablePanel from '../table';

export const CONFIG = {
  id: 'logs',
  settings: {
    general: [panelConfig, tableColConfig, timeConfig],
    overrides: null,
  },
  name: 'Logs List',
  description: 'Logs list',
  multiNodes: false,
  default: {
    query_type: QUERY_TYPE.NON_AGG,
    dataset: DATA_SET.LOGS,
    limit: 10,
    page: 1,
    responseType: 'series',
  },
  showTableView: false,
  configurableSource: false,
};

const LogsListPanel = (props) => (
  <TablePanel {...props} rowId="id" multiNodes={CONFIG.multiNodes} />
);

export default LogsListPanel;
