import React, { useEffect, useMemo, useState } from 'react';
import Select from 'components/Form/Select';
import Chip from '@mui/material/Chip';
import { fetchValues } from 'api/otel';
import { useSelector } from 'store';
import debounce from 'lodash/debounce';
import { resolveTemplateVariables } from 'modules/dashboards/utils';
import { OTEL_VALUES } from 'modules/dashboards/components/DashboardSettings/Variables/QueryVariable';
import { toast } from 'react-hot-toast';
import { DATA_SET } from 'constants/data-sources';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { GET_ISSUE_COLUMN_VALUES } from 'graphql/issues/queries';
import moment from 'moment';
import {
  GET_ALARM_COLUMN_VALUES,
  GET_ALARM_STATE_LABELS,
} from 'graphql/alarms/queries';
import { ALARM_STATES } from 'constants';
import client from 'utils/apolloClient';

const filter = createFilterOptions();

const ValueField = ({
  name,
  value,
  onChange,
  field,
  type,
  dataset,
  variableOptions,
  from,
  to,
  resolvedVariablesValues,
  isMulti = false,
  ...rest
}) => {
  const workspaceId = useSelector((state) => state.workspace.workspace.id);
  const [query, setQuery] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setData(null);
    handleOnOpen();
  }, [field]);

  const debouncedSearch = useMemo(() => debounce(setQuery, 400), []);

  const filterValues = useMemo(() => {
    if (data?.values) {
      return data.values.map((item) => ({
        ...item,
        label: item.value,
        icon: item.metricType && <Chip label={item.metricType} />,
        endIcon: true,
        groupBy: variableOptions?.length ? 'Values' : undefined,
      }));
    }
    return [];
  }, [data]);

  const getOptionLabel = (option) => {
    return option?.metric || (option.value ? `${option.value}` : '');
  };

  const isOptionEqualToValue = (option, value) => {
    return option.value === (value?.metric || value.value);
  };

  const handleOnchange = (newValue, { action, option, removedValue }) => {
    const isCreate = action === 'create-option';
    if (!isMulti) {
      onChange(name, {
        ...value,
        value: isCreate ? newValue : newValue.value,
        metricType: newValue.metricType,
        unit: newValue.unit,
        temporality: newValue.temporality,
      });
      return;
    }

    if (!option) {
      onChange(name, []);
      return;
    }
    if (removedValue) {
      onChange(
        name,
        value.filter((val) => !(val === option.value)),
      );
      return;
    }
    onChange(name, [
      ...value,
      {
        value: isCreate ? newValue : newValue.value,
      },
    ]);
  };

  const handleOnOpen = () => {
    if (!field) {
      return;
    }
    setLoading(true);
    (async () => {
      try {
        if (dataset === DATA_SET.ALARMS) {
          if (field === 'state') {
            setData({
              key: field,
              values: Object.values(ALARM_STATES).map((value) => ({ value })),
            });
            return;
          }
          if (field === 'serviceName') {
            const result = await client.query({
              query: GET_ALARM_STATE_LABELS,
              variables: {
                query: {
                  labels: { _contains: [[field]] },
                  alarm: {
                    workspace_id: { _eq: workspaceId },
                  },
                  last_evaluated_at: {
                    _gte: moment(from).toJSON(),
                    _lte: moment(to).toJSON(),
                  },
                },
              },
              skip: !field,
            });
            setData({
              key: field,
              values: (result.data?.rows || [])
                .map(
                  (row) => row.labels.find((label) => label[0] === field)?.[1],
                )
                .map((value) => ({ value })),
            });
            return;
          }
          if (field === 'name') {
            const result = await client.query({
              query: GET_ALARM_COLUMN_VALUES(field),
              variables: {
                query: {
                  workspace_id: { _eq: workspaceId },
                  alarm_states: {
                    last_evaluated_at: {
                      _gte: moment(from).toJSON(),
                      _lte: moment(to).toJSON(),
                    },
                  },
                },
              },
              skip: !field,
            });

            setData({
              key: field,
              values: (result.data?.rows || [])
                .map((row) => row.name)
                .map((value) => ({ value })),
            });
            return;
          }
        }

        if (dataset === DATA_SET.ISSUES) {
          const result = await client.query({
            query: GET_ISSUE_COLUMN_VALUES(field),
            variables: {
              query: {
                workspace_id: { _eq: workspaceId },
                last_occurrence_at: {
                  _gte: moment(from).toJSON(),
                  _lte: moment(to).toJSON(),
                },
              },
              column: field,
            },
            skip: !field,
          });
          setData({
            key: field,
            values: (result.data?.rows || []).map((row) => ({
              value: row[field],
            })),
          });
          return;
        }

        const result = await fetchValues({
          dataset,
          query: resolveTemplateVariables(
            {
              query,
              key: field,
              type,
              workspaceId,
              from,
              to,
              filters: [],
            },
            resolvedVariablesValues,
          ),
        });

        setData(result);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Select
      fullWidth
      freeSolo
      isLoading={loading}
      component={Select}
      options={(variableOptions || [])
        .filter((opt) => opt.isMulti === isMulti)
        .filter((opt) => opt.queryType === OTEL_VALUES)
        .concat(filterValues)}
      margin="none"
      size="small"
      placeholder="value"
      value={value}
      onChange={handleOnchange}
      onOpen={handleOnOpen}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.title,
        );
        if (inputValue !== '' && !isExisting) {
          filtered.unshift({
            inputValue,
            value: inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      onInputChange={(e, v, reason) => {
        if (reason === 'input') {
          debouncedSearch(v);
        }
      }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default ValueField;
