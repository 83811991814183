import React, { useMemo, useState } from 'react';
import Select from 'components/Form/Select';
import { fetchKeys } from 'api/otel';
import { useSelector } from 'store';
import DataTypeIcon from 'components/DataTypeIcon';
import debounce from 'lodash/debounce';
import { Operators } from 'constants/aggregations';
import { resolveTemplateVariables } from 'modules/dashboards/utils';
import { OTEL_KEY } from 'modules/dashboards/components/DashboardSettings/Variables/QueryVariable';
import { toast } from 'react-hot-toast';
import { DATA_SET } from 'constants/data-sources';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const getFilters = (filters) =>
  filters.filter((f) => {
    if (!f.field) return false;
    const requiresValue =
      Operators.find((o) => o.value === f.operator).requiresValue !== false;
    return requiresValue ? f.value !== undefined : true;
  });

const KeyField = ({
  name,
  value,
  onChange,
  dataset,
  from,
  to,
  variableOptions,
  filters = [],
  isMulti,
  resolvedVariablesValues,
  ...rest
}) => {
  const workspaceId = useSelector((state) => state.workspace.workspace.id);
  const [query, setQuery] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setQuery(value);
      }, 400),
    [],
  );

  const filterKeys = useMemo(() => {
    return (data ?? []).map((i) => ({
      ...i,
      icon: <DataTypeIcon type={i.type} />,
      groupBy: variableOptions?.length ? 'Keys' : undefined,
    }));
  }, [data]);

  const getOptionLabel = (option) => {
    return option.field || '';
  };

  const isOptionEqualToValue = (option, value) => {
    return option.field === value.field && option.type === value.type;
  };

  const handleOnchange = (newValue, { action, option, removedValue }) => {
    const isCreate = action === 'create-option';
    if (!isMulti) {
      onChange(name, {
        ...value,
        field: isCreate ? newValue : newValue.field,
        type: isCreate ? 'string' : newValue.type,
        value: null,
      });
      return;
    }

    if (!option) {
      onChange(name, []);
      return;
    }
    if (removedValue) {
      onChange(
        name,
        value.filter(
          (val) => !(val.field === option.field && val.type === option.type),
        ),
      );
      return;
    }
    onChange(name, [
      ...value,
      {
        field: isCreate ? option : option.field,
        type: isCreate ? 'string' : option.type,
      },
    ]);
  };

  const handleOnOpen = () => {
    setLoading(true);
    (async () => {
      try {
        if (dataset === DATA_SET.ALARMS) {
          setData([
            { field: 'serviceName', type: 'root' },
            { field: 'name', type: 'root' },
            { field: 'state', type: 'root' },
          ]);
          return;
        }
        if (dataset === DATA_SET.ISSUES) {
          setData([
            { field: 'service_name', type: 'root' },
            { field: 'dataset', type: 'root' },
            { field: 'error_type', type: 'root' },
            { field: 'severity', type: 'root' },
            { field: 'count', type: 'root' },
            { field: 'trace_id', type: 'root' },
            { field: 'last_occurrence_at', type: 'root' },
            { field: 'first_occurence_at', type: 'root' },
          ]);
          return;
        }
        const result = await fetchKeys({
          dataset,
          query: {
            query,
            workspaceId,
            from,
            to,
            filters: resolveTemplateVariables(
              getFilters(filters),
              resolvedVariablesValues,
            ),
          },
        });

        setData(result);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Select
      fullWidth
      isLoading={loading}
      size="small"
      margin="none"
      placeholder="Field"
      component={Select}
      options={(variableOptions || [])
        .filter((opt) => isMulti || opt.isMulti === isMulti)
        .filter((opt) => opt.queryType === OTEL_KEY)
        .concat(filterKeys)}
      value={value}
      onChange={handleOnchange}
      onOpen={handleOnOpen}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.title,
        );
        if (inputValue !== '' && !isExisting) {
          filtered.unshift({
            inputValue,
            field: inputValue,
            type: 'string',
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      onInputChange={(e, v, reason) => {
        if (reason === 'input') {
          debouncedSearch(v);
        }
      }}
      freeSolo
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={getOptionLabel}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default KeyField;
